@import url(../color.css);
.errorParagraph {
  width: 85%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--error);
}
.errorParagraph::before {
  margin-right: 5px;
  display: inline;
  content: '⚠';
}
.notValid {
  padding: 5px !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: var(--error) !important;
}
/* .notValidInput {
  border-color: hsl(0, 92%, 51%);
} */
