.modalBackground {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.popupModal {
  position: relative; 
  display: flex;
  width: 60%;
  flex-direction: column;
  border-radius: 20px;
  padding: 30px;
  background: var(--gray-4);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
}

.closePopupModal{
  position: absolute;
  top: 10px; 
  right: 10px; 
  cursor: pointer; 

}

