* {
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "FujitsuInfinityPro";
  src: local("..\\assets\\fonts\\FujitsuInfinityPro-Regular.woff2"),
    url(../assets/fonts/FujitsuInfinityPro-Regular.woff2) format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "FujitsuInfinityPro";
  src: local("..\\assets\\fonts\\FujitsuInfinityPro-RegularItalic.woff2"),
    url(../assets/fonts/FujitsuInfinityPro-RegularItalic.woff2) format("woff2");
  font-style: italic;
}
@font-face {
  font-family: "FujitsuInfinityPro";
  src: local("..\\assets\\fonts\\FujitsuInfinityPro-Bold.woff2"),
    url(../assets/fonts/FujitsuInfinityPro-Bold.woff2) format("woff2");
  font-weight: bold;
}
@font-face {
  font-family: "FujitsuInfinityPro";
  src: local("..\\assets\\fonts\\FujitsuInfinityPro-ExtraBold.woff2"),
    url(../assets/fonts/FujitsuInfinityPro-ExtraBold.woff2) format("woff2");
  font-weight: bolder;
}
@font-face {
  font-family: "FujitsuInfinityPro", bold, italic;
  src: local("..\\assets\\fonts\\FujitsuInfinityPro-BoldItalic.woff2"),
    url(../assets/fonts/FujitsuInfinityPro-BoldItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: bold;
}

#root {
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: "FujitsuInfinityPro", Arial, Helvetica, sans-serif;
}
