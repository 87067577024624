.inputText {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: var(--gray-3);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding-left: 10px;
  width: 85%;
  margin-bottom: 8px;
}

.disableInputText {
  background-color: var(--gray-4) !important;
  cursor: default;
}
.inputTextLabel {
  margin-bottom: 8px;
  font-size: 15px;
  white-space: nowrap;
}

.inputTextLabel[aria-required]::after {
  content: ' *';
  color: var(--red);
}