.topHeader {
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
  .processesList {
    text-align: left;
    margin-left: -20px;
  }
  .processesList ul {
    display: flex;
    padding: 0.7em 1em 0.7em 2em;
  }
  .processesList li {
    list-style: none;
    padding: 15px;
  }
  .processesList a {
    text-decoration: none;
    color: var(--black);
    font-weight: 600;
    text-transform: capitalize;
    font-size: 13.3px;
  }
  .processesList a:focus {
    color: var(--magenta);
  }
  .processesList a:hover {
    color: var(--gray-2);
  }
  .buttonInHeader {
    padding: 10px;
    color: var(--white);
    background-color: var(--red);
    border: 1px solid var(--black);
    border-radius: 5px;
    cursor: pointer;
    background-image: "url(../../assets/home.webp) " !important ;
  }
  .buttonInHeader:hover {
    background-color: var(--magenta);
    translate: 0 -0.5px;
  }
  .refIdText {
    font-weight: 700;
    margin-right: 20px;
  }
  .headerButton {
    height: 35px;
    width: 190px;
    border-radius: 5px;
    border-color: transparent;
    background-color: var(--gray-4);
    margin-left: 15px;
    margin-top: 8px;
    cursor: pointer;
    font-weight: 700;
  }
  .homeButton {
    cursor: pointer !important;
    height: 35px;
    width: 35px;
    margin-left: 20px;
    margin-top: 8px;
  }
  