.sectionHeader {
  display: flex;
  letter-spacing: 1px;
  color: var(--white);
  background-color: var(--red);
  border: 1px solid var(--magenta);
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0.6em;
}
.sectionChildren {
  background-color: var(--gray-4);
}
