/* CheckBoxContainer component */
.checkBoxContainer {
    margin-bottom: 1em;
    margin-top: 0.5em;
  }
  .checkBoxTitle {
    font-weight: 200;
    margin-bottom: 4px;
  }
  .checkBoxChildrenContainer {
    display: flex;
  }
  .checkBoxChild {
    width: 100%;
    display: flex;
  }
  .checkBoxSecondChild {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  /* CheckBox component */
  .labelAndInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 10px;
    width: 85%;
  
  }
  .checkBoxChildContainer {
    display: flex;
    align-items: center;
    width: 85%;
    margin-bottom:10px
  }

  .checkBoxChildContainer label {
    margin-bottom: 0;
  }

  .checkBoxLabel {
    font-weight: 200;   
  }
  .checkBoxInput {
    margin-right: 0.7em;
  }
  