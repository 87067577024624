.attachmentButton {
  border-radius: 10px;
  cursor: pointer;
  padding: 8px;
  margin: 10px 0 0 10px;
  background-color: var(--magenta);
  color: var(--white);
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.8px;
  border: none;
}
.attachmentButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}
