.headerLayout {
  display: flex;
  align-items: center;
  height: 6em;
  background-image: linear-gradient(250deg, var(--magenta) 10%, var(--red));
  justify-content: space-between;
  padding-right: 1em;
  padding-left: 1em;
}
.logoAndName {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 20em;
}
.fujistuImg {
  width: 7em;
  margin-left: 20px;
  margin-right: 10px;
}

.appName {
  font-size: x-large;
  font-weight: bold;
  padding-left: 15px;
  border-left: 3px solid var(--white);
  color: var(--white);
}
.title {
  color: var(--white);
  font-size: xx-large;
}
.logInOutContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  height: 100%;
  padding-right: 1em;
  width: 20em;
}
.name {
  color: var(--gray-4);
  margin-right: 20px;
}
