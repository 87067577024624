.homeContainer {
  width: 100%;
  min-height: calc(100vh - 10.7em);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-4);
  background-image: url("../../../assets/loginbackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.homeContainer ul {
  list-style-type: none;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  background-color: var(--gray-4);
  padding: 2em 2em 1em 2em;
  border-radius: 5px;
}

.homeContainer ul h3 {
  font-weight: bold;
  margin-bottom: 0.5em;
  color: var(--blue);
}
.homeContainer ul li {
  padding: 15px;
  border-radius: 5px;
  background-color: var(--magenta);
  font-size: large;
  font-weight: bolder;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.homeContainer ul a {
  padding: 3px;
  text-decoration: none;
  color: var(--white);
}

.homeContainer ul li:hover {
  background-color: var(--blue);
  border-radius: 5px;
  color: var(--gray-3);
}

.homeContainer ul li a:hover {
  color: var(--gray-3);
}
.homeContainer img {
  padding-top: 100px;
}
