.mainButton {
  background-color: var(--blue);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  line-height: 20px;
  text-decoration: none;
  padding: 11px;
  border: 2px solid var(--blue);
  text-align: center;
  transition: all 250ms;
  vertical-align: baseline;
  white-space: nowrap;
  min-width: 10em;
}
.mainButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}
.mainButtonDisabled {
  background-color: var(--gray-3);
  border-radius: 5px;
  border-width: 0;
  color: var(--gray-2);
  cursor: not-allowed;
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  line-height: 20px;
  text-decoration: none;
  padding: 11px;
  text-align: center;
  transition: all 250ms;
  vertical-align: baseline;
  white-space: nowrap;
  min-width: 10em;
}

.secondaryButton {
  background-color: var(--gray-4);
  border-radius: 5px;
  border: 2px solid var(--blue);
  color: var(--blue);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  line-height: 20px;
  text-decoration: none;
  padding: 11px;
  text-align: center;
  transition: all 250ms;
  vertical-align: baseline;
  white-space: nowrap;
  min-width: 10em;
}

.secondaryButtonDisabled {
  background-color: var(--gray-3);
  border-radius: 5px;
  border-width: 0;
  color: var(--gray-2);
  cursor: default;
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  line-height: 20px;
  text-decoration: none;
  padding: 11px;
  text-align: center;
  transition: all 250ms;
  vertical-align: baseline;
  white-space: nowrap;
  min-width: 10em;
}

.secondaryButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.tertiaryButton {
  background-color: var(--gray-4);
  border-radius: 5px;
  border: 2px solid var(--magenta);
  color: var(--blue);
  border-radius: 5px;
  color: var(--magenta);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  line-height: 20px;
  text-decoration: none;
  padding: 11px;
  text-align: center;
  transition: all 250ms;
  vertical-align: baseline;
  white-space: nowrap;
  min-width: 10em;
}

.tertiaryButtonDisabled {
  background-color: var(--gray-3);
  border-radius: 5px;
  border-width: 0;
  color: var(--gray-2);
  cursor: default;
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  line-height: 20px;
  text-decoration: none;
  padding: 11px;
  text-align: center;
  transition: all 250ms;
  vertical-align: baseline;
  white-space: nowrap;
  min-width: 10em;
}

.tertiaryButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}
