.tab {
    position: absolute;
    left: -5%;
    transform: scale(-1) translateY(-50%);
    background: var(--white);
    border-radius: 0px 10px 10px 0px;
    text-align: center;
    width: 5%;
    height: 20%;
    color: var(--blue);
    padding: 5px;
    cursor: pointer;
    transition: left 0.3s ease-in-out, background 0.1s linear;
    writing-mode: vertical-lr;
    font-weight: bolder;
    box-shadow: 1px -2px 8px 0px rgba(0, 0, 0, 0.2);
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab:hover {
  background: var(--gray-4);
}

.ModalContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 600px;
    z-index: 9999;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
}

.ModalContent {
    min-height: 20%;
    background: var(--white);
    position: absolute;
    top: 10vh;
    width: 100%;
}

.ModalContainer.open {
    transform: translateX(0);
}

.close-button {
  position: absolute;
  top: 35px;
  right: 35px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-button:hover {
  color: var(--gray-2);
}

.ModalContainer .form {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 30px;
  background: var(--gray-4);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
}

.ModalContainer .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ModalContent {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}