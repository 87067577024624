.rowFormContainer {
    display: flex;
    width: 100%;
    margin-bottom: 0.8em;
  }
  .rowFormContainerItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .rowFormSingleItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 10px;
  }
  .rowFormSingleItemFull {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .rightItem {
    align-items: end;
  }
  .rightItem label {
    width: 85%;
  }
  .rowFormContainerItem label {
    margin-bottom: 4px;
  }
  