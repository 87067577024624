:root{
    --red: #EA0000;
    --magenta: #D80084;
    --blue: #2400B0;
    --cyan: #00E7EF;
    --emerald: #008224;
    --lime: #61D600;
    --yellow: #FFE700;
    --orange: #FF8000;
    --black: #000000;
    --gray-1: #3C3C3C;
    --gray-2: #6D6E70;
    --gray-3: #DCDCDC;
    --gray-4: #EFEFEF;
    --white: #FFFFFF;
    --error: #AD0A0A;
}