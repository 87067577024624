.stepIndicator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  height: 4em;
}
.stepCircle {
  margin-top: 0px;
  text-decoration: none;
  width: 80px;
  height: 30px;
  background: var(--gray-4);
  color: var(--gray-1);
  /* box-shadow: 0px 3px 12px var(--gray-2); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  cursor: default;
  user-select: none;
  position: relative;
  font-weight: bold;
  font-size: 22px;
  transition: all 600ms ease-in-out;
  padding-left: 55px;
  padding-top: 0.5px;
}
.active {
  background: var(--blue) !important;
  color: var(--white) !important;
}
.stepName {
  margin-top: 12px;
  color: var(--gray-2) !important;
  text-align: center;
  font-size: small;
  line-height: 17px;
  width: 100%;
}
.outerContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 3em;
  width: 11em;
  margin-bottom: 20px;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.innerContainer p {
  word-wrap: break-word;
}
.horizontalLine {
  width: 7em;
  background: var(--magenta);
  height: 2px;
  border-width: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
}
.outerContainer:last-of-type {
  margin-left: -40px;
}
.outerContainer:last-of-type .horizontalLine {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .horizontalLine {
    width: 5em;
  }
}
@media only screen and (max-width: 700px) {
  .stepIndicator {
    display: none;
  }
}
