.multi_processList {
    display: flex;
    flex-direction: column;
}

.processList_row--header {
    font-size: medium;
    font-weight:bold;
    margin: 24px;
    color: var(--black);
    margin: 0;
    padding: 5px 5px 5px 20px;
}