.SignOutButton {
  display: flex;
  align-items: center;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  font-size: medium;
  color: var(--white);
  margin-right: 20px;
  margin-top: 4px;
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
}

.SignOutButton:hover {
  background-color: rgba(185, 185, 185, 0.61);
  cursor: pointer;
}

.SignOutButtonIcon {
  width: 1.6em;
  height: 1.6em;
  padding-right: 5px;
}
