.formCard {
    padding: 20px;
    background: var(--white);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .form {
    width: 85%;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 30px;
    background: var(--gray-4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 3em;
  }
  .modalBackground {
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: center;
  }
  .loadingModal {
    font-weight: bold;
    display: flex;
    width: 60%;
    flex-direction: column;
    border-radius: 20px;
    padding: 30px;
    background: var(--gray-4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    align-items: center;
  }

  .childCheckboxContainer {
    display: grid;
    margin-bottom: 20px; 
    padding: 10px; /* Adds padding around the child checkbox container */
    background-color: white; /* Set the background to white */
    border-radius: 5px; /* Optionally, add rounded corners for a nicer effect */
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  }

  .childCheckbox {
    margin-left: 10px;
    /* margin-bottom: ; Adds space between each child checkbox */
  }

  .dateStyle{
    display: flex;
  }

  .date-spacing{
margin-right: 20px;
  }

  .admin-button-process{
    /* min-width: 250px; */
    border-radius: 5px;
    border-color: var(--magenta);
    color: var(--magenta);
  }
