.uploadButton {
  margin-top: 2em;
  padding: 10px;
  color: var(--white);
  background-color: var(--magenta);
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  border: 2px solid var(--magenta);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeUpload {
  margin-top: 8px;
  padding: 10px;
  color: var(--magenta);
  font-weight: bolder;
  font-family: "FujitsuInfinityPro";
  border: 2px solid var(--magenta);
  border-radius: 5px;
  cursor: pointer;
}
.uploadButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}
.removeUpload:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.addIcon {
  width: 1.2em;
  height: 1.2em;
  margin-left: 0.7em;
}
