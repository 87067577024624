.LoadingSpinner {
  display: grid;
  justify-content: center;
  padding-top: 64px;
}

.LoginContainer {
  min-height: calc(100vh - 6em);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/loginbackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
}

.LoginContainer h1 {
  padding: 0.8em;
}

.LoginForm {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #efefef;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1em 4em 3em 4em;
}

.Login {
  padding-top: 1em;
  padding-bottom: 0em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Login input {
  padding: 1em;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-bottom: 1em;
  width: 30em;
  border-radius: 5px;
}

.Login input:focus {
  border: 1px solid var(--gray-2);
  outline: none;
}

.Login label {
  color: black;
  padding-bottom: 0.5em;
  font-size: large;
  font-weight: bolder;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 25em;
  padding-top: 1.5em;
}

.EmailInput {
  margin-top: 1.5em;
}
