.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listContainer .page-item {
  border-radius: 6px;
}

.listTable {
  width: 100% !important;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px 0 20px !important;
}

.listColumnHeader {
  background-color: var(--magenta);
  text-align: left;
  color: var(--white);
}

.listColumnHeader th{
  position: relative;
  padding-left: 50px;
  width: 15%;
  user-select: none;
}

/* Skall sortering användas kan nedan css avkommenteras */
/* .listColumnHeader th:not(.sorted-header):hover::before {
  content: ''; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray-3);
  opacity: 30%;
  cursor: pointer;
} */

.listBody td {
  padding-left: 50px !important;
  font-weight: bold;
}

.listBody tr:nth-child(odd) {
  background-color: var(--white);
  cursor: pointer !important;
}

.listBody tr:nth-child(even) {
  background-color: var(--gray-3);
  cursor: pointer !important;
}

th,
td {
  padding: 8px;
}

.downloadFileButton {
  cursor: pointer !important;
  height: 35px;
  width: 35px;
  position: absolute;
  transform: translateY(-20%);
  margin-left: 20px;
}

.downloadFileButton:hover {
  border: 1px solid var(--black);
  border-radius: 5px;
}

.listBody tr:hover {
  color: var(--white);
  background-color: var(--blue) !important;
}

.listBody tr:hover .downloadFileButton{
  filter: invert(100%);
}

.listBody tr {
  color: var(--blue);
}

.listContainer .spinnerContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  margin: auto 0 auto 0;
}

.sorted-header {
  cursor: pointer;
  position: relative;
}

.sorted-header.asc::after {
  content: '▼';
}

.sorted-header.desc::after {
  content: '▲';
}

.sorted-header::after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.sorted-header::before {
  content: ''; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray-3);
  opacity: 40%;
  z-index: 0;
}

.paginationContainer {
  margin-top: 100px;
}